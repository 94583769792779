import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import CookieConsent from "./Components/CookieConsent";

import Home from "./Pages/Home";
import VIP from "./Pages/Vip";
import Servers from "./Pages/Servers";
import NotFound from "./Pages/NotFound";
import BanPolicy from "./Pages/BanPolicy";
import AdminRecruitment from "./Pages/AdminRecruitment";
import InGameAdminGuide from "./Pages/InGameAdminGuide";
import DiscordAdminGuide from "./Pages/DiscordAdminGuide";

import Snowfall from 'react-snowfall';

import './styles.css';

function App() {
    return (
        <div>
            <Snowfall
                snowflakeCount={75}
                snowflakeSize={{ min: 1, max: 4 }}
                snowWind={{ min: -1, max: 10 }}
                opacity={{ min: 0.1, max: 0.8 }}
                style={{ zIndex: 9999, position: 'fixed', width: '100vw', height: '100vh', }}
            />
            <Navbar />
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/servers" element={<Servers />} />
                    <Route path="/vip" element={<VIP />} />
                    <Route path="/recruitment" element={<AdminRecruitment />} />
                    <Route path="/ingame-admin-guide" element={<InGameAdminGuide />} />
                    <Route path="/discord-admin-guide" element={<DiscordAdminGuide />} />
                    <Route path="/banpolicy" element={<BanPolicy />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
            <CookieConsent />
            <Footer />
        </div>
    );
}

export default App;
